import React from "react";
import "./home.css";
import image1 from "./images/tractor_parts/transmission_parts/1.png";
import image2 from "./images/tractor_parts/transmission_parts/2.png";
import image3 from "./images/tractor_parts/transmission_parts/3.png";
import image4 from "./images/tractor_parts/transmission_parts/4.png";
import image5 from "./images/tractor_parts/transmission_parts/5.png";
import image6 from "./images/tractor_parts/transmission_parts/6.png";
import image7 from "./images/tractor_parts/hydraulic_parts/7.png";
import image8 from "./images/tractor_parts/hydraulic_parts/8.png";
import image9 from "./images/tractor_parts/hydraulic_parts/9.png";
import image10 from "./images/tractor_parts/hydraulic_parts/10.png";
import image11 from "./images/tractor_parts/hydraulic_parts/11.png";
import image12 from "./images/tractor_parts/hydraulic_parts/12.png";
import image13 from "./images/tractor_parts/hydraulic_parts/13.png";
import image14 from "./images/tractor_parts/hydraulic_parts/14.png";
import image15 from "./images/tractor_parts/hydraulic_parts/15.png";
import image16 from "./images/tractor_parts/hydraulic_parts/16.png";
import image17 from "./images/tractor_parts/hydraulic_parts/17.png";
import image18 from "./images/tractor_parts/hydraulic_parts/18.png";
import image19 from "./images/tractor_parts/suspension_parts/19.png";
import image20 from "./images/tractor_parts/suspension_parts/20.png";
import image21 from "./images/tractor_parts/suspension_parts/21.png";
import image42 from "./images/tractor_parts/suspension_parts/42.png";
import image22 from "./images/tractor_parts/steering_parts/22.png";
import image23 from "./images/tractor_parts/steering_parts/23.png";
import image24 from "./images/tractor_parts/steering_parts/24.png";
import image25 from "./images/tractor_parts/steering_parts/25.png";
import image26 from "./images/tractor_parts/electrical_parts/26.png";
import image27 from "./images/tractor_parts/electrical_parts/27.png";
import image28 from "./images/tractor_parts/electrical_parts/28.png";
import image29 from "./images/tractor_parts/electrical_parts/29.png";
import image30 from "./images/tractor_parts/electrical_parts/30.png";
import image31 from "./images/tractor_parts/electrical_parts/31.png";
import image32 from "./images/tractor_parts/electrical_parts/32.png";
import image33 from "./images/tractor_parts/electrical_parts/33.png";
import image34 from "./images/tractor_parts/miscellaneous_parts/34.png";
import image35 from "./images/tractor_parts/miscellaneous_parts/35.png";
import image36 from "./images/tractor_parts/miscellaneous_parts/36.png";
import image37 from "./images/tractor_parts/miscellaneous_parts/37.png";
import image38 from "./images/tractor_parts/miscellaneous_parts/38.png";
import image39 from "./images/tractor_parts/miscellaneous_parts/39.png";
import image40 from "./images/tractor_parts/miscellaneous_parts/40.png";
import image41 from "./images/tractor_parts/miscellaneous_parts/41.png";
import image43 from "./images/tractor_parts/miscellaneous_parts/43.png";
import image44 from "./images/tractor_parts/miscellaneous_parts/44.png";
import image45 from "./images/tractor_parts/miscellaneous_parts/45.png";
import image46 from "./images/tractor_parts/miscellaneous_parts/46.png";

export default function Tractor_parts() {
  return (
    <div id="new_div">
      <div id="tractor_parts">
        <h1 id="heading">TRACTOR PARTS</h1>
        <div className="row">
          {/* transmission parts div start  */}
          <h2 className="sub_heading">Transmission Parts</h2>
          <div class="left_div_pic">
            {/* image row 1 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image1}></img>
                  </div>
                  <div className="names">Gears</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image2}></img>
                  </div>
                  <div className="names">Shafts</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image3}></img>
                  </div>
                  <div className="names">Check Nut</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image4}></img>
                  </div>
                  <div className="names">Bevel Gears</div>
                </div>
              </div>
            </div>
            {/* image row 1 end */}

            {/* image row 2 start */}
            {/* <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image5}></img>
                  </div>
                  <div className="names">PTO Shaft</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image6}></img>
                  </div>
                  <div className="names">Crown Wheel Pinion</div>
                </div>
              </div>

              <div class="col" style={{display: 'none'}}>
                <div className="product" >
                  <div className="imgs">
                    <img src=""></img>
                  </div>
                  <div className="names">Oil Pump</div>
                </div>
              </div>

              <div class="col" style={{display: 'none'}}>
                <div className="product">
                  <div className="imgs">
                    <img src=""></img>
                  </div>
                  <div className="names">Nozzle</div>
                </div>
              </div>
            </div> */}
            {/* image row 2 end */}
          </div>
          <div class="right_div_list">
            <ul id="product_list" className="list">
              <li>GEARS</li>
              <li>SHAFTS</li>
              <li>CHECK NUT</li>
              <li>BEVEL GEARS</li>
              <li>PTO SHAFT</li>
              <li>CROWN WHEEL PINION</li>
              <li>SPACE FOR SHAFT</li>
            </ul>
          </div>
          <br />
          {/* transmission parts div ends */}

          {/* hydraulics div start */}
          <h2 className="sub_heading">Hydraulic Parts</h2>
          <div className="left_div_list">
            <ul id="product_list" className="list">
              <li>HYDRAULIC PUMP</li>
              <li>CONTROL VALVE</li>
              <li>COUPLING HYDRAULIC PUMP</li>
              <li>HYDRAULIC FILTER</li>
              <li>HYD CHAMBER BODY</li>
              <li>HYD LIFT ARM</li>
              <li>HYD LIFT PUMP</li>
              <li>REPAIR KIT</li>
              <li>HYD LIFT PUMP PISTON</li>
              <li>HYD PISTON FOR RAM</li>
              <li>CAM BLOCK BUSH PLAIN</li>
              <li>RELIEF VALVE</li>
              <li>HYD RAM</li>
            </ul>
          </div>

          <div className="right_div_pic">
            {/* image row 1 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image7}></img>
                  </div>
                  <div className="names">HYD Pump</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image8}></img>
                  </div>
                  <div className="names">Control Valve</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image9}></img>
                  </div>
                  <div className="names">Coupling Hydraulic Pump</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image10}></img>
                  </div>
                  <div className="names">Hydraulic Filter</div>
                </div>
              </div>
            </div>
            {/* image row 1 end */}

            {/* image row 2 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image11}></img>
                  </div>
                  <div className="names">HYD Chamber Body</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image12}></img>
                  </div>
                  <div className="names">HYD Lift Arm</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image13}></img>
                  </div>
                  <div className="names">HYD Lift Pump</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image14}></img>
                  </div>
                  <div className="names">Repair Kit</div>
                </div>
              </div>
            </div>
            {/* image row 2 end */}

            {/* image row 3 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image15}></img>
                  </div>
                  <div className="names">Lift Pump Piston</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image16}></img>
                  </div>
                  <div className="names">HYD Ram</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image17}></img>
                  </div>
                  <div className="names">HYD Piston</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image18}></img>
                  </div>
                  <div className="names">Relief Valve</div>
                </div>
              </div>
            </div>
            {/* image row 2 end */}
          </div>

          {/* hydraulics div end */}

          {/* suspension parts div start  */}
          <h2 className="sub_heading">Suspension Parts</h2>
          <div class="left_div_pic">
            {/* image row 1 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image19}></img>
                  </div>
                  <div className="names">Tie Rod End</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image20}></img>
                  </div>
                  <div className="names">Thrust Bearing</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image21}></img>
                  </div>
                  <div className="names">Stub Axle</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image42}></img>
                  </div>
                  <div className="names">Tie Rod Big</div>
                </div>
              </div>
            </div>
            {/* image row 1 end */}
          </div>
          <div class="right_div_list">
            <ul id="product_list" className="list">
              <li>TIE ROD END</li>
              <li>THRUST BEARING</li>
              <li>STUB AXLE</li>
              <li>TIE ROD BIG</li>
            </ul>
          </div>
          <br />
          {/* suspension parts div ends */}

          {/* steering div start */}
          <h2 className="sub_heading">Steering Parts</h2>
          <div className="left_div_list">
            <ul id="product_list" className="list">
              <li>STEERING BOX ASSEMBLY</li>
              <li>STEERING SHAFT</li>
              <li>DROP ARM</li>
              <li>STEERING WHEEEL</li>
              <li>STEERING CAP</li>
              <li>UJ CROSS</li>
            </ul>
          </div>

          <div className="right_div_pic">
            {/* image row 1 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image22}></img>
                  </div>
                  <div className="names">Drop Arm</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image23}></img>
                  </div>
                  <div className="names">Steering Assy</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image24}></img>
                  </div>
                  <div className="names">Steering Shaft</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image25}></img>
                  </div>
                  <div className="names">Steering Wheel</div>
                </div>
              </div>
            </div>
            {/* image row 1 end */}
          </div>

          {/* steering div end */}

          {/* electrical parts div start  */}
          <h2 className="sub_heading">Electrical Parts</h2>
          <div class="left_div_pic">
            {/* image row 1 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image26}></img>
                  </div>
                  <div className="names">Alternator</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image27}></img>
                  </div>
                  <div className="names">Dashboard Cluster</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image28}></img>
                  </div>
                  <div className="names">Headlight</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image29}></img>
                  </div>
                  <div className="names">Oil Pressure Guage</div>
                </div>
              </div>
            </div>
            {/* image row 1 end */}

            {/* image row 2 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image30}></img>
                  </div>
                  <div className="names">Starter Motor</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image31}></img>
                  </div>
                  <div className="names">Temprature Guage</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image32}></img>
                  </div>
                  <div className="names">Ignition Switch</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image33}></img>
                  </div>
                  <div className="names">Indicator Lights</div>
                </div>
              </div>
            </div>
            {/* image row 2 end */}
          </div>
          <div class="right_div_list">
            <ul id="product_list" className="list">
              <li>STARTER MOTOR</li>
              <li>OIL GAUGE</li>
              <li>TEMPERATURE GAUGE</li>
              <li>HEAD LIGHT</li>
              <li>IGNITION KEY</li>
              <li>DASH BOARD LIGHT</li>
            </ul>
          </div>
          <br />
          {/* electrical parts div ends */}

          {/* Miscellaneous div start */}
          <h2 className="sub_heading">Miscellaneous Parts</h2>
          <div className="left_div_list">
            <ul id="product_list" className="list">
              <li>CLUTCH PLATE</li>
              <li>CLUTCH HUB</li>
              <li>CLUTCH FORK</li>
              <li>CLUTCH FINGER</li>
              <li>HUB FRONT</li>
              <li>HUB REAR</li>
              <li>TOP LINK</li>
              <li>LIFT ASSEMBLY</li>
              <li>HUB BOLT</li>
              <li>RADIATOR HOSE</li>
              <li>LEAF SPRING END</li>
              <li>BUSH FOR STUB AXLE</li>
              <li>DRAW BAR</li>
              <li>NUT FOR STUB AXLE</li>
            </ul>
          </div>

          <div className="right_div_pic">
            {/* image row 1 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image34}></img>
                  </div>
                  <div className="names">Clutch Plate</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image35}></img>
                  </div>
                  <div className="names">Wheel Hub</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image36}></img>
                  </div>
                  <div className="names">Hub Bolt</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image37}></img>
                  </div>
                  <div className="names">Lynch Pins</div>
                </div>
              </div>
            </div>
            {/* image row 1 end */}

            {/* image row 2 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image38}></img>
                  </div>
                  <div className="names">Radiator Hose</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image39}></img>
                  </div>
                  <div className="names">Top Link</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image40}></img>
                  </div>
                  <div className="names">Clutch Hub</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image41}></img>
                  </div>
                  <div className="names">Water Pump</div>
                </div>
              </div>
            </div>
            {/* image row 2 end */}

            {/* image row 3 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image43}></img>
                  </div>
                  <div className="names">Brake Drum</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image44}></img>
                  </div>
                  <div className="names">Clutch Cover </div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image45}></img>
                  </div>
                  <div className="names">Draw Bar</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image46}></img>
                  </div>
                  <div className="names">Levelling Assembly</div>
                </div>
              </div>
            </div>
            {/* image row 2 end */}
          </div>

          {/* Miscellaneous div end */}
        </div>
      </div>
    </div>
  );
}
