import React from 'react'
import './home.css'
import image1 from './images/footer/1.jpeg'
import image2 from './images/footer/2.jpeg'

export default function Footer() {
  return (
    <div>
       <nav  class="navbar navbar-dark bg-dark">
  <div id='brand_div' class="container-fluid">
    <p id='branding'><a href="https://digitops.ga" target="_blank">Designed & Developed By Digitops In India</a></p><br/>
    <div className="footer_images">
      <img src={image1}></img>
      <img src={image2}></img>
    </div>
  </div>
</nav>
    </div>
  )
}
