import React from "react";
import "./home.css";
import emailjs from "emailjs-com";

export default function Contact() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_qcvsf41",
        "template_kjthv4b",
        e.target,
        "YGYatLZs4tioZSB-Z"
      )
      .then(e.target.reset(), alert("message sent"));
  }
  return (
    <div id="new_div">
      <div id="contact">
        <hr />
        <h1 id="heading">Contact Us</h1>

        <div className="row">
          <div className="col">
            <h2 className="sub_heading">Address</h2>
            <p className="description">
              Shivam Enterprises <br /> D-52 Foundry Nagar Agra 282006 (U.P){" "}
              <br />
              India
            </p>
            <h2 className="sub_heading">Contact Details</h2>
            <p className="description">
              <a href="tel:9837888558">
                <i class="fa-solid fa-phone"></i> 91 9837888558
              </a>
            </p>
            <p className="description">
              <a href="mailto:info@shivamspares.com">
                <i class="fa-solid fa-envelope"></i> info@shivamspares.com
              </a>
            </p>
          </div>
          <div className="col">
            <h2 className="sub_heading">Write To Us</h2>

            <form onSubmit={sendEmail}>
              <div class="rendered-form">
                <div class="formbuilder-text form-group field-name">
                  <h6 for="name" class="description">
                    Name<span class="formbuilder-required">*</span>
                  </h6>
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    access="false"
                    id="name"
                    required="required"
                    aria-required="true"
                  />
                </div>
                <div class="formbuilder-text form-group field-number">
                  <h6 for="number" class="description">
                    Number<span class="formbuilder-required">*</span>
                  </h6>
                  <input
                    type="tel"
                    class="form-control"
                    name="number"
                    access="false"
                    maxlength="12"
                    id="number"
                    required="required"
                    aria-required="true"
                  />
                </div>
                <div class="formbuilder-text form-group field-email">
                  <h6 for="email" class="description">
                    Email<span class="formbuilder-required">*</span>
                  </h6>
                  <input
                    type="email"
                    class="form-control"
                    name="email"
                    access="false"
                    id="email"
                    required="required"
                    aria-required="true"
                  />
                </div>
                <div class="formbuilder-textarea form-group field-message">
                  <h6 for="message" class="description">
                    Message<span class="formbuilder-required">*</span>
                  </h6>
                  <textarea
                    type="textarea"
                    class="form-control"
                    name="message"
                    access="false"
                    id="message"
                    required="required"
                    aria-required="true"
                  ></textarea>
                </div>
                <div
                  class="formbuilder-button form-group field-submit"
                  style={{ margin: "5px" }}
                >
                  <button
                    type="submit"
                    class="btn-primary btn"
                    name="submit"
                    access="false"
                    id="submit"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
