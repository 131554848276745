import React from "react";
import "./home.css";
import image1 from "./images/generators/1.png";
import image2 from "./images/generators/2.png";

export default function Generators() {
  return (
    <div>
      <div id="new_div">
        {/* generators div starts */}
        <div id="generators">
          <h1 id="heading">GENERATORS</h1>
          <div className="row">
            <div class="left_div_list">
              <ul id="product_list" className="list">
                <li>WATER COOLED GENERATORS</li>
                <li>AIR COOLED GENERATORS</li>
              </ul>
            </div>
            <div class="right_div_pic">
              <div class="row">
                <div class="col">
                  <div className="product">
                    <div className="imgs">
                      <img src={image1}></img>
                    </div>
                    <div className="names">Water Cooled Generators</div>
                  </div>
                </div>

                <div class="col">
                  <div className="product">
                    <div className="imgs">
                      <img src={image2}></img>
                    </div>
                    <div className="names">Air Cooled Generators</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* generators div ends */}
      </div>
    </div>
  );
}
