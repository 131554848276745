import React from "react";
import "./home.css";
import image1 from "./images/harvester/1.png";
import image2 from "./images/harvester/2.png";
import image3 from "./images/harvester/3.png";
import image4 from "./images/harvester/4.png";
import image5 from "./images/harvester/5.png";
import image6 from "./images/harvester/6.png";
import image7 from "./images/harvester/7.png";
import image8 from "./images/harvester/8.png";
import image9 from "./images/harvester/9.png";
import image10 from "./images/harvester/10.png";
import image11 from "./images/harvester/11.png";
import image12 from "./images/harvester/12.png";

export default function Harvester() {
  return (
    <div id="new_div">
      <div id="harvester">
        <h1 id="heading">HARVESTER</h1>

        <div className="row">
          {/* harvester parts div start  */}
          <div class="left_div_pic">
            {/* image row 1 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image1}></img>
                  </div>
                  <div className="names">Gear Coupling</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image2}></img>
                  </div>
                  <div className="names">Crankshaft</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image3}></img>
                  </div>
                  <div className="names">Sieve</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image4}></img>
                  </div>
                  <div className="names">Cutterbar</div>
                </div>
              </div>
            </div>
            {/* image row 1 end */}

            {/* image row 2 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image5}></img>
                  </div>
                  <div className="names">Disc Blade</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image6}></img>
                  </div>
                  <div className="names">Temprature Guage</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image7}></img>
                  </div>
                  <div className="names">Gear</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image8}></img>
                  </div>
                  <div className="names">Blade</div>
                </div>
              </div>
            </div>
            {/* image row 2 end */}

            {/* image row 3 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image9}></img>
                  </div>
                  <div className="names">Rasp Bar</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image10}></img>
                  </div>
                  <div className="names">Rotary Blade</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image11}></img>
                  </div>
                  <div className="names">Spacer Reel</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image12}></img>
                  </div>
                  <div className="names">Spool</div>
                </div>
              </div>
            </div>
            {/* image row 2 end */}
          </div>
          <div class="right_div_list">
            <ul id="product_list" className="list">
              <li>FINGERS</li>
              <li>BLADE</li>
              <li>RASP BAR</li>
              <li>SPOOL</li>
              <li>BALL JOINT</li>
              <li>GEARS</li>
              <li>BEARING ASSEMBLY</li>
              <li>PLASTIC BUSH</li>
              <li>CRANKSHAFT</li>
              <li>RUBBER COUPLING</li>
              <li>DISENGAGER ASSEMBLY</li>
              <li>BALL JOINT</li>
              <li>BEARING</li>
            </ul>
          </div>
          <br />
          {/* harvester parts div ends */}
        </div>
      </div>
    </div>
  );
}
