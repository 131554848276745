import React from "react";
import "./home.css";
import image1 from "./images/engine_parts/1.png";
import image2 from "./images/engine_parts/2.png";
import image3 from "./images/engine_parts/3.png";
import image4 from "./images/engine_parts/4.png";
import image5 from "./images/engine_parts/5.png";
import image6 from "./images/engine_parts/6.png";
import image7 from "./images/engine_parts/7.png";
import image8 from "./images/engine_parts/8.png";
import image9 from "./images/engine_parts/9.png";
import image10 from "./images/engine_parts/10.png";
import image11 from "./images/engine_parts/11.png";
import image12 from "./images/engine_parts/12.png";
import image13 from "./images/engine_parts/13.png";
import image14 from "./images/engine_parts/14.png";
import image15 from "./images/engine_parts/15.png";
import image16 from "./images/engine_parts/16.png";
import image17 from "./images/engine_parts/17.png";
import image18 from "./images/engine_parts/18.png";
import image19 from "./images/engine_parts/19.png";
import image20 from "./images/engine_parts/20.png";
import image21 from "./images/engine_parts/21.png";
import image22 from "./images/engine_parts/22.png";
import image23 from "./images/engine_parts/23.png";
import image24 from "./images/engine_parts/24.png";

export default function Engine_parts() {
  return (
    <div id="new_div">
      <div id="engine_parts">
        <h1 id="heading">ENGINE PARTS</h1>
        <div className="row">
          <div class="left_div_pic">
            {/* image row 1 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image1}></img>
                  </div>
                  <div className="names">Cylinder Liner</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image2}></img>
                  </div>
                  <div className="names">Piston</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image3}></img>
                  </div>
                  <div className="names">Piston Rings</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image4}></img>
                  </div>
                  <div className="names">Cr Bearing And Bushes</div>
                </div>
              </div>
            </div>
            {/* image row 1 end */}

            {/* image row 2 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image5}></img>
                  </div>
                  <div className="names">Crankshaft</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image6}></img>
                  </div>
                  <div className="names">Connecting Rod</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image7}></img>
                  </div>
                  <div className="names">Oil Pump</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image8}></img>
                  </div>
                  <div className="names">Nozzle</div>
                </div>
              </div>
            </div>
            {/* image row 2 end */}

            {/* image row 3 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image9}></img>
                  </div>
                  <div className="names">Valve</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image10}></img>
                  </div>
                  <div className="names">Gaskets</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image11}></img>
                  </div>
                  <div className="names">Air Cleaner</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image12}></img>
                  </div>
                  <div className="names">Silencer</div>
                </div>
              </div>
            </div>
            {/* image row 3 end */}

            {/* image row 4 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image13}></img>
                  </div>
                  <div className="names">Cylinder Head</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image14}></img>
                  </div>
                  <div className="names">Valve Seat</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image15}></img>
                  </div>
                  <div className="names">Valve Guide</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image16}></img>
                  </div>
                  <div className="names">Push Rod</div>
                </div>
              </div>
            </div>
            {/* image row 4 end */}

            {/* image row 5 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image17}></img>
                  </div>
                  <div className="names">Fuel Pipes</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image18}></img>
                  </div>
                  <div className="names">Rocker Cover</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image19}></img>
                  </div>
                  <div className="names">Valve Cup and Collet</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image20}></img>
                  </div>
                  <div className="names">Rocker Lever</div>
                </div>
              </div>
            </div>
            {/* image row 5 end */}

            {/* image row 6 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image21}></img>
                  </div>
                  <div className="names">Feed Pump</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image22}></img>
                  </div>
                  <div className="names">Exhaust Manifold</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image23}></img>
                  </div>
                  <div className="names">Oil Filter</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image24}></img>
                  </div>
                  <div className="names">Gears</div>
                </div>
              </div>
            </div>
            {/* image row 6 end */}
          </div>
          <div class="right_div_list">
            <ul id="product_list" className="list">
              <li>CYLINDER LINER</li>
              <li>PISTONS</li>
              <li>PISTON RINGS</li>
              <li>CR BEARINGS & BUSHES</li>
              <li>CRANKSHAFT</li>
              <li>CONNECTING ROD</li>
              <li>OIL PUMPS</li>
              <li>NOZZLE</li>
              <li>VALVE</li>
              <li>GASKETS</li>
              <li>AIRCLEANER</li>
              <li>SILENCER</li>
              <li>CYLINDER HEAD</li>
              <li>VALVE SEAT</li>
              <li>VALVE GUIDE</li>
              <li>PUSH ROD</li>
              <li>FUEL PIPES</li>
              <li>ROCKER COVER</li>
              <li>VALVE CUP & COLLET</li>
              <li>ROCKER LEVER </li>
              <li>FUEL FILTER</li>
              <li>VALVE SPRING</li>
              <li>FEED PUMP</li>
              <li>OIL FILTER</li>
              <li>ELEMENT</li>
              <li>GEARS</li>
              <li>EXHAUST MANIFOLD</li>
              <li>OIL SEAL</li>
            </ul>
          </div>
        </div>
      </div>
      {/* engine parts div ends */}
    </div>
  );
}
