import React, { useState } from 'react'
import Popup_img from './images/popup_img.jpg';

export default function Banner() {

    const [style, setStyle] = useState("popup_div");

    const Close = () => {
        setStyle("popup_div_close");
      }

  return (

    <div id={style}>

        <input id='cross_button' type="button" value="x" onClick={Close}></input>

        <img src={Popup_img}></img>
      
    </div>
    
  );
}
