import React from "react";

export default function Header() {
  return (
    <div>
      <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            Shivam Enterprises
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="#company_profile">
                  Company Profile
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#agriculture">
                  Agriculture
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#engine_parts">
                  Engine Parts
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#generators">
                  Generators
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#tractor_parts">
                  Tractor Parts
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#harvester">
                  Harvester Parts
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#truck">
                  Truck Parts
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contact">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <marquee direction="left">Manufactures of spare parts can contact us and write to us through the form below</marquee>
    </div>
  );
}
