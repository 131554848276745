import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import Form from './components/Form';
import Engine_parts from './components/Engine_parts';
import Generators from './components/Generators';
import Tractor_parts from './components/Tractor_parts';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Harvester from './components/Harvester';
import whatsapp from './whatsapp.png';
import Truck from './components/Truck';
import Popup from './components/Banner';

function App() {
  return (
    <div className="App">
      <Header/>
      <Popup/>
      <Home/>
      <Engine_parts/>
      <Generators/>
      <Tractor_parts/>
      <Harvester/>
      <Truck/>
      <Contact/>
      <Footer/>
      <div className="whatsapp">
        <a href="https://wa.me/919837888558" target="_blank"><img src={whatsapp}></img></a>
      </div>
    </div>
  );
}

export default App;
