import React from 'react'
import './home.css'
import image1 from './images/truck/1.png'
import image2 from './images/truck/2.png'
import image3 from './images/truck/3.png'
import image4 from './images/truck/4.png'
import image5 from './images/truck/5.png'
import image6 from './images/truck/6.png'
import image7 from './images/truck/7.png'

export default function Truck() {
  return (
    <div id='new_div'>

        <div id="truck">
            <h1 id="heading">TRUCK</h1>

            <div className="row">

                {/* truck parts div start  */}
          <div class="left_div_list">
            <ul id="product_list" className="list">
            <li>CYLINDER LINER</li>
<li>PISTONS</li>
<li>PISTON RINGS</li>
<li>ENGINE VALVES</li>
<li>CONNECTING ROD</li>
<li>KING PIN BUSH</li>
<li>VALVE GUIDES</li>
<li>SYNCHRONISER RING</li>
<li>VALVE SEAT</li>

            </ul>
          </div>

          <div class="right_div_pic">
            {/* image row 1 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image1}></img>
                  </div>
                  <div className="names">Cylinder Liner</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image2}></img>
                  </div>
                  <div className="names">Piston</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image3}></img>
                  </div>
                  <div className="names">Piston Rings</div>
                </div>
              </div>

              <div class="col">
                <div className="product" >
                  <div className="imgs">
                    <img src={image4}></img>
                  </div>
                  <div className="names">Connecting Rod</div>
                </div>
              </div>
            </div>
            {/* image row 1 end */}

            {/* image row 2 start */}
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image5}></img>
                  </div>
                  <div className="names">King Pin Bush</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image6}></img>
                  </div>
                  <div className="names">Syncronizer</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image7}></img>
                  </div>
                  <div className="names">Valve Seat</div>
                </div>
              </div>

              <div class="col" >
                <div className="product" style={{display: 'none'}}>
                  <div className="imgs">
                    <img src=""></img>
                  </div>
                  <div className="names">Blade</div>
                </div>
              </div>
            </div>
            {/* image row 2 end */}
           
          </div>
      {/* truck parts div ends */}

            </div>

        </div>

    </div>
  )
}
