import React from "react";
import "./home.css";
import map from "./images/world-map.png";
import engine from "./images/engine.png";
import agriculture from "./images/agriculture.png";
import tractor from "./images/tractor.png";
import image1 from "./images/1.png";
import image2 from "./images/2.png";
import image3 from "./images/3.png";
import image4 from "./images/4.png";
import image5 from "./images/brands/5.png";
import image6 from "./images/brands/6.png";
import image7 from "./images/brands/7.png";
import image8 from "./images/brands/8.png";
import image9 from "./images/brands/9.png";
import image10 from "./images/brands/10.png";
import image11 from "./images/brands/11.png";
import image12 from "./images/brands/12.png";
import image13 from "./images/brands/13.png";
import image14 from "./images/brands/14.png";
import image15 from "./images/brands/15.png";
import image16 from "./images/brands/16.png";
import image17 from "./images/brands/17.png";

export default function Home() {
  console.clear();
  return (
    <div id="home_div">
      {/* company profile div starts */}
      <div id="company_profile">
        <h1 id="heading">COMPANY PROFILE</h1>

        <div className="content">
          <div class="row">
            <div class="col">
              <h2 className="sub_heading">Shivam Enterprises</h2>
              <p className="description">
                Is 100% Exporter of Engine, Tractor, and Auto Parts. At Shivam
                Spares we bridges gap between cost effective small scale sector
                and highly technical but equally expensive organized sector. We
                can develop any Automobile component as per the customer's
                satisfactions. Our present range includes Engine spare parts,
                Agriculture Parts, Tractors Parts and Standard Parts.
              </p>
              <p className="description">
                Quality and customer focus is of utmost importance to us. We
                give satisfaction to our clients through excellence:
              </p>
              <ul className="description">
                <li>Cutting Edge Technology</li>
                <li>Competitive Price</li>
              </ul>
              <p className="description">
                Our efforts to bring world class quality products with zero
                defects. We are committed to quality and customer's satisfaction
                and to become world class organization as we move forward. We
                have strong product development and quality control facility
                wherein we do exhaustive inspection before dispatching the goods
                to our overseas customers.
              </p>
              <p className="description">
                <u>
                  <b>Welcome to Shivam Enterprises</b>
                </u>
                <br /> Leading Manufacturer and Exporter of Diesel engines,
                Pumpsets, Generators, Engine parts, Tractor parts and
                Agriculture parts
              </p>
            </div>
            <div class="col">
              <img className="map_image" src={map}></img>

              <h4>WE ARE COMMITTED FOR EXCELLENCE</h4>

              <div className="image_div">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <div className="products">
                        <div className="imgs">
                          <img src={engine}></img>
                        </div>
                        <div className="names">Engine</div>
                      </div>
                    </div>

                    <div class="col">
                      <div className="products">
                        <div className="imgs">
                          <img src={agriculture}></img>
                        </div>
                        <div className="names">Agriculture</div>
                      </div>
                    </div>

                    <div class="col">
                      <div className="products">
                        <div className="imgs">
                          <img src={tractor}></img>
                        </div>
                        <div className="names">Tractor</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="brand_div">
          <h2 className="brand_heading">
            <u>Specialised In Brands</u>
          </h2>

          <div className="brand_image_div">
            <div className="row">
              <div className="col">
                <img className="brand_image" src={image5}></img>
              </div>

              <div className="col">
                <img className="brand_image" src={image6}></img>
              </div>

              <div className="col">
                <img className="brand_image" src={image7}></img>
              </div>

              <div className="col">
                <img className="brand_image" src={image8}></img>
              </div>

              <div className="col">
                <img className="brand_image" src={image9}></img>
              </div>

              <div className="col">
                <img className="brand_image" src={image10}></img>
              </div>

              <div className="col">
                <img className="brand_image" src={image11}></img>
              </div>

              <div className="col">
                <img className="brand_image" src={image12}></img>
              </div>

              <div className="col">
                <img className="brand_image" src={image13}></img>
              </div>
              <div className="col">
                <img className="brand_image" src={image14}></img>
              </div>
              <div className="col">
                <img className="brand_image" src={image15}></img>
              </div>
              <div className="col">
                <img className="brand_image" src={image16}></img>
              </div>
              <div className="col">
                <img className="brand_image" src={image17}></img>
              </div>
            </div>
            <p className="disclaimer">
              (All Stated Brand Names Serve Only Comparison Purpose & may not be
              used as Original Reference.)
            </p>
          </div>
        </div>
      </div>
      {/* company profile div ends */}
      {/* agriculture div starts */}
      <div id="agriculture">
        <h1 id="heading">AGRICULTURE</h1>
        <div className="row">
          <div class="left_div_list">
            <ul id="product_list" className="list">
              <li>DIESEL ENGINES</li>
              <li>PUMP SETS</li>
              <li>DISC PLOUGH</li>
              <li>TILLER</li>
            </ul>
          </div>
          <div class="right_div_pic">
            <div class="row">
              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image1}></img>
                  </div>
                  <div className="names">Diesel Engines</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image2}></img>
                  </div>
                  <div className="names">Pump Set</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image3}></img>
                  </div>
                  <div className="names">Disc Plough</div>
                </div>
              </div>

              <div class="col">
                <div className="product">
                  <div className="imgs">
                    <img src={image4}></img>
                  </div>
                  <div className="names">Tiller</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* agriculture div ends */}

      {/* engine parts div starts */}
    </div>
  );
}
